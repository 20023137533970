import axios from "axios";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function transformClerkName(
  username: string | null,
  firstName: string | null,
  lastName: string | null,
) {
  return uglifyName(
    username ?? `${firstName}${lastName ? " " + lastName : ""}`,
  );
}

export function beautifyName(name: string) {
  if (!name) return "";
  return name
    .split("-")
    .map((word) => word[0]!.toUpperCase() + word.slice(1))
    .join(" ");
}

export function uglifyName(name: string | null | undefined) {
  if (!name) return undefined;
  return name.toLowerCase().split(" ").join("-");
}

export async function fetchUrl(id: string) {
  if (!id) return "";
  try {
    const baseUrl =
      process.env.NODE_ENV === "production"
        ? process.env.NEXT_PUBLIC_PROD_BASE_URL
        : process.env.NEXT_PUBLIC_DEV_BASE_URL;
    const apiUrl = `${baseUrl}/api`;

    const res = await axios.get(`${apiUrl}/r2/getUrl`, {
      params: { id },
    });

    return res.data as string;
  } catch (err) {
    console.log(err);
    return "";
  }
}
