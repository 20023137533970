/* eslint-disable @next/next/no-img-element */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import {
  MdPlayArrow,
  MdPause,
  MdVolumeMute,
  MdVolumeUp,
  MdSkipNext,
  MdSkipPrevious,
} from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { Button } from "./ui/button";
import { Maximize, Repeat } from "lucide-react";
import { beautifyName, cn } from "~/lib/utils";
import { Slider } from "./ui/slider";
import Link from "next/link";
import { Rating } from "@mui/material";
import { HeartIcon, StarIcon } from "lucide-react";
import { HeartFilledIcon } from "@radix-ui/react-icons";
import { getSongRating } from "~/components/AudioPlayer";
import type { SerializedSong } from "~/types";
import usePlayer from "~/hooks/useMusicPlayerHook";

const MiniPlayer = () => {
  const {
    currentTrack,
    setCurrentTrack,
    addToFavorites,
    removeFromFavorites,
    isFavorited,
    setIsFavorite,
    nextTrack,
    prevTrack,
    repeat,
    setRepeat,
    audioRef,
    isPlaying,
    setIsPlaying,
    isReady,
    setIsReady,
    duration,
    setDuration,
    currentProgress,
    bufferedProgress,
    setBufferedProgress,
    volume,
    setVolume,
    progressValue,
    lastVolume,
    setLastVolume,
    elapsedDisplay,
    durationDisplay,
    handleProgressChange,
    handleProgressChangeStart,
    handleProgressChangeEnd,
    handleAudioEnd,
    togglePlayPause,
    handleVolumeChange,
    setCurrentProgress,
    user,
  } = usePlayer();

  if (!user) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center justify-between bg-[#0E0B17]">
      <div className="flex w-full items-center justify-between p-6">
        {currentTrack && (
          <audio
            ref={audioRef}
            preload="metadata"
            onDurationChange={(e) => {
              setDuration(e.currentTarget.duration);
              const buffered = e.currentTarget.buffered;
              if (buffered.length > 0) {
                setBufferedProgress(
                  (buffered.end(buffered.length - 1) /
                    e.currentTarget.duration) *
                    100,
                );
              }
            }}
            onTimeUpdate={(e) => {
              setCurrentProgress(e.currentTarget.currentTime);
            }}
            onPlaying={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onCanPlay={() => setIsReady(true)}
            onEnded={handleAudioEnd}
          >
            <source type="audio/mpeg" src={currentTrack.songUrl} />
          </audio>
        )}
        <div className="flex w-2/5 items-center gap-8">
          <Link
            href={currentTrack ? `/album/${currentTrack.album.id}` : "/"}
            passHref
          >
            <button
              disabled={!currentTrack}
              className="flex items-center gap-4 disabled:cursor-not-allowed"
            >
              <img
                src={currentTrack?.imageUrl ?? "/Error/dizzy.png"}
                alt="Song Image"
                className="h-20 w-20 rounded-lg border-2 border-primary"
              />
            </button>
          </Link>
          {currentTrack && (
            <div className="flex flex-col gap-1">
              <h3 className="max-h-12 max-w-60 overflow-hidden text-ellipsis pl-1 text-base font-bold">
                {currentTrack.title}
              </h3>
              <p className="pl-1 text-sm">
                {beautifyName(currentTrack.owner.name)}
              </p>
              <Rating
                name="read-only"
                precision={0.5}
                sx={{
                  path: {
                    color: "#ff851b",
                  },
                }}
                emptyIcon={
                  <StarIcon
                    style={{ opacity: 0.3 }}
                    fontSize="inherit"
                    color="white"
                    fill="gray"
                  />
                }
                value={getSongRating(currentTrack as unknown as SerializedSong)}
                readOnly
              />
            </div>
          )}
          {currentTrack && user.id !== currentTrack.owner.id && (
            <>
              {isFavorited ? (
                <Button
                  variant="ghost"
                  className="flex items-center gap-2"
                  onClick={() => {
                    removeFromFavorites.mutate({
                      songId: currentTrack.id,
                    });

                    setIsFavorite(false);
                  }}
                >
                  <HeartFilledIcon className="h-6 w-6 text-red-500" />
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  className="flex items-center gap-2"
                  onClick={() => {
                    addToFavorites.mutate({
                      songId: currentTrack.id,
                    });

                    setIsFavorite(true);
                  }}
                >
                  <HeartIcon className="h-6 w-6 text-red-500" />
                </Button>
              )}
            </>
          )}
        </div>

        <div className="mr-auto flex w-1/4 -translate-y-2 flex-col gap-1">
          <div className="mt-4 grid grid-cols-3 items-center p-2">
            <span className="text-xs">
              {elapsedDisplay} / {durationDisplay}
            </span>
            <div className="flex items-center gap-2 justify-self-center">
              <Button
                disabled={!prevTrack}
                className="disabled:cursor-not-allowed"
                onClick={() => {
                  if (prevTrack) {
                    setCurrentTrack(prevTrack);
                    localStorage.setItem(
                      "currentTrack",
                      JSON.stringify(prevTrack),
                    );
                    audioRef.current?.load();
                    void audioRef.current?.play();
                  }
                }}
                aria-label="Previous"
                variant={"ghost"}
              >
                <MdSkipPrevious size={30} />
              </Button>
              {!isReady && currentTrack ? (
                <CgSpinner size={24} className="animate-spin" />
              ) : (
                <button
                  disabled={!isReady}
                  onClick={() => {
                    togglePlayPause();
                    if (audioRef.current) {
                      if (isPlaying) {
                        void audioRef.current.pause();
                      } else {
                        void audioRef.current.play();
                      }
                    }
                  }}
                  aria-label={isPlaying ? "Pause" : "Play"}
                  className="rounded-full hover:bg-accent disabled:cursor-not-allowed disabled:opacity-50"
                >
                  {isPlaying ? (
                    <MdPause
                      size={28}
                      className="box-content rounded-full bg-white p-2 hover:bg-gray-200"
                      color="black"
                    />
                  ) : (
                    <MdPlayArrow
                      size={28}
                      className="box-content rounded-full bg-white p-2 hover:bg-gray-200"
                      color="black"
                    />
                  )}
                </button>
              )}
              <Button
                disabled={!nextTrack}
                onClick={() => {
                  if (nextTrack) {
                    setCurrentTrack(nextTrack);
                    localStorage.setItem(
                      "currentTrack",
                      JSON.stringify(nextTrack),
                    );
                    audioRef.current?.load();
                    void audioRef.current?.play();
                  }
                }}
                aria-label="Next"
                variant={"ghost"}
              >
                <MdSkipNext size={30} />
              </Button>
            </div>

            <div className="flex items-center gap-3 justify-self-end">
              <Button
                variant={"ghost"}
                className={cn("text-primary hover:bg-accent", "", {
                  "text-green-300 hover:text-green-500": repeat,
                })}
                onClick={() => setRepeat(!repeat)}
              >
                {<Repeat />}
              </Button>
            </div>
          </div>
          <div className="relative w-full">
            <div
              className="absolute left-0 top-0 z-0 h-full rounded-lg bg-white bg-opacity-50"
              style={{ width: `${bufferedProgress}%` }}
            ></div>
            <Slider
              className="relative w-full cursor-pointer rounded-lg border border-primary"
              defaultValue={[progressValue]}
              value={[(currentProgress / duration) * 100]}
              max={100}
              step={1}
              onValueChange={handleProgressChange}
              onPointerDown={handleProgressChangeStart}
              onPointerUp={handleProgressChangeEnd}
            />
          </div>
        </div>

        <div className="flex translate-y-5 items-center gap-4">
          {volume === 0 ? (
            <MdVolumeMute
              className="cursor-pointer"
              size={24}
              onClick={() => {
                setVolume(lastVolume);
              }}
            />
          ) : (
            <MdVolumeUp
              className="cursor-pointer"
              size={24}
              onClick={() => {
                setLastVolume(volume);
                setVolume(0);
              }}
            />
          )}
          <Slider
            className="w-20 cursor-pointer rounded-lg border border-primary"
            defaultValue={[volume * 100]}
            max={100}
            step={1}
            onValueChange={handleVolumeChange}
          />
          <Link href={currentTrack ? `/song/${currentTrack.id}` : "/"}>
            <Button
              variant="ghost"
              className="flex items-center gap-2"
              disabled={!currentTrack}
            >
              <Maximize size={24} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MiniPlayer;
