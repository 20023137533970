import Link from "next/link";
import { Facebook, Instagram, Twitter } from "lucide-react";
import { cn } from "~/lib/utils";

export default function Footer({
  className,
}: {
  className?: string | undefined;
}) {
  return (
    <footer className={cn("z-[999]", className)}>
      <div className="mt- mx-auto flex flex-wrap items-center justify-center border-t-2 px-4 py-8 lg:justify-between">
        <div className="flex flex-wrap justify-center">
          <ul className="flex items-center space-x-4">
            <li className="hover:underline">
              <a href={"/"}>Home</a>
            </li>
            <li className="hover:underline">
              <Link href={"/about"}>About</Link>
            </li>
            <li className="hover:underline">
              <Link href={"/faq"}>FAQ</Link>
            </li>

            <li className="hover:underline">
              <Link href={"/contact"}>Contact US</Link>
            </li>
            <li className="hover:underline">
              <Link href={"/terms"}>Terms & Condition</Link>
            </li>
            <li className="hover:underline">
              <Link href={"/privacy"}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="mt-4 flex justify-center space-x-4 lg:mt-0">
          <Link href={""}>
            <Facebook />
          </Link>
          <Link href={""}>
            <Twitter />
          </Link>
          <Link href={""}>
            <Instagram />
          </Link>
        </div>
      </div>
      <div className="pb-2">
        <p className="text-center">
          &copy; {new Date().getFullYear()} All rights reserved.
        </p>
      </div>
    </footer>
  );
}
