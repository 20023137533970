import React, { useEffect, useState } from "react";
import { cn } from "~/lib/utils";
import { api } from "~/utils/api";
import { useRouter } from "next/router";

declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}

export function AdBanner({ className }: { className: string }) {
  const [isSubscribed, setIsSubscribed] = React.useState(true);
  const [isAdBlockerActive, setIsAdBlockerActive] = useState(false);
  const { data } = api.user.getSelf.useQuery({
    albums: false,
    songs: false,
    favorites: false,
    ratings: false,
  });
  const router = useRouter();

  useEffect(() => {
    if (data) {
      setIsSubscribed(!!data.stripeSubscriptionId);
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.adsbygoogle && window.adsbygoogle.length === 0) {
        setIsAdBlockerActive(true);
      }
    }, 3000);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      setIsAdBlockerActive(true); // Fallback in case of an error
    }

    return () => clearTimeout(timer);
  }, []);

  if (data && isSubscribed) {
    return null;
  }

  const pages = ["/about", "/signup", "/login"];

  if (pages.includes(router.pathname)) {
    return null;
  }

  return (
    <aside
      className={cn("flex flex-col gap-4 border bg-purple-950 p-8", className)}
    >
      <h2 className="text-center text-2xl font-bold">Support Us</h2>
      {isAdBlockerActive && (
        <p className="text-center">
          Please consider supporting us by disabling your ad blocker.
        </p>
      )}
      <ins
        className="adsbygoogle max-h-[350px] max-w-[350px]"
        style={{ display: "block", overflow: "hidden" }}
        data-ad-client="ca-pub-5088978098286512"
        data-ad-slot="7537062280"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>
  );
}
