import type { AppProps } from "next/app";
import { api } from "~/utils/api";
import "~/styles/globals.css";
import Providers from "~/providers";
import Layout from "~/components/Layout";
import { useEffect } from "react";
import { useRouter } from "next/router";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { env } from "~/env.js";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "always",

    // Enable debug mode in development
    // loaded: (posthog) => {
    //   if (process.env.NODE_ENV === "development") posthog.debug();
    // },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

type pageProps = {
  messages: IntlMessages;
  now: number;
};

type props = Omit<AppProps<pageProps>, "pageProps"> & {
  pageProps: pageProps;
};

const MyApp = ({ Component, pageProps }: props) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Providers pageProps={pageProps}>
      <PostHogProvider client={posthog}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </PostHogProvider>
    </Providers>
  );
};

export default api.withTRPC(MyApp);
