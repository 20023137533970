import { useRouter } from "next/router";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Toaster } from "./ui/toaster";
import CookieConsent from "./Banner";
import Sidebar from "./Sidebar";
import MusicPlayer from "./MusicPlayer";
import { api } from "~/utils/api";
import { useUser } from "@clerk/nextjs";
import React from "react";
import { type User } from "@prisma/client";
import { cn } from "~/lib/utils";
import { AdBanner } from "./AdBanner";

export default function Layout({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const locale = router.locale;

  const { isSignedIn } = useUser();

  const { data: user } = api.user.getSelf.useQuery(
    {
      albums: false,

      songs: false,
      favorites: false,
      ratings: false,
    },
    {
      enabled: isSignedIn,
    },
  );

  const direction = locale === "ar" ? "rtl" : "ltr";

  let isShown =
    router.pathname.includes("admin") || router.pathname.includes("about");

  let MusicPlayerIsShown =
    router.pathname.startsWith("/song/") || router.pathname.includes("about");

  isShown = !isShown && !!isSignedIn;
  MusicPlayerIsShown = !MusicPlayerIsShown && !!isSignedIn;

  return (
    <div dir={direction} className=" ">
      <div className="flex">
        {isShown ? <Sidebar user={user as User} /> : null}
        <div className="flex w-full flex-col">
          <Navbar user={user as User} />
          <main className="relative mb-auto min-h-screen bg-background">
            <div className="flex h-full">
              <div className="w-full">{children}</div>
              <AdBanner className="h-full w-40" />
            </div>
            <Toaster />
            <CookieConsent />
          </main>
        </div>
      </div>
      <Footer
        className={cn("", "", {
          "mb-40": MusicPlayerIsShown,
          "mb-4": !MusicPlayerIsShown,
        })}
      />

      {MusicPlayerIsShown ? <MusicPlayer /> : null}
    </div>
  );
}
