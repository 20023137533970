// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { useEffect, useRef, useState, useMemo } from "react";
import { api } from "~/utils/api";
import { fetchUrl } from "~/lib/utils";
import { type SerializedSong } from "~/types";
import { useMusicPlayer } from "~/providers";

const formatDurationDisplay = (duration: number): string => {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);
  return [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":");
};

export default function usePlayer() {
  const { currentTrack, setCurrentTrack } = useMusicPlayer();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [bufferedProgress, setBufferedProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.4);
  const [progressValue, setProgressValue] = useState(0);
  const [repeat, setRepeat] = useState(false);
  const [lastVolume, setLastVolume] = useState(0.4);
  const [isFavorited, setIsFavorite] = useState(false);
  const [temporaryPause, setTemporaryPause] = useState(false);
  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currentProgress);
  const [nextTrack, setNextTrack] = useState<SerializedSong | null>(null);
  const [prevTrack, setPrevTrack] = useState<SerializedSong | null>(null);
  const [hasReached75Percent, setHasReached75Percent] = useState(false);

  const { data: user } = api.user.getSelf.useQuery({
    albums: false,
    songs: false,
    favorites: false,
    ratings: false,
  });

  const { data: fav } = api.user.findFavoriteSongs.useQuery();
  const { data: album } = api.album.get.useQuery({
    id: currentTrack?.album ? currentTrack.album.id : "",
  });

  const incrementSongViews = api.song.incrementViews.useMutation();

  const favoritedSongs = useMemo(() => fav, [fav]);

  useEffect(() => {
    async function fetchData() {
      if (album) {
        const currentTrackIndex = album.songs.findIndex(
          (s) => s.id === (currentTrack?.id ?? ""),
        );

        if (currentTrackIndex !== -1) {
          const nextTrack = album.songs[currentTrackIndex + 1];
          const prevTrack = album.songs[currentTrackIndex - 1];

          const nextTrackSongUrl = await fetchUrl(nextTrack?.id ?? "");
          const prevTrackSongUrl = await fetchUrl(prevTrack?.id ?? "");

          const nextTrackImageUrl = currentTrack?.imageUrl;
          const prevTrackImageUrl = currentTrack?.imageUrl;

          const nextTrackWithUrls = nextTrack && {
            ...nextTrack,
            songUrl: nextTrackSongUrl,
            imageUrl: nextTrackImageUrl,
          };

          const prevTrackWithUrls = prevTrack && {
            ...prevTrack,
            songUrl: prevTrackSongUrl,
            imageUrl: prevTrackImageUrl,
          };

          setNextTrack(nextTrackWithUrls ?? null);
          setPrevTrack(prevTrackWithUrls ?? null);
        }
      }
    }

    void fetchData();
  }, [album, currentTrack]);

  useEffect(() => {
    if (favoritedSongs && currentTrack) {
      const found = favoritedSongs.find((s) => s.id === currentTrack.id);
      setIsFavorite(!!found);
    }
  }, [favoritedSongs, currentTrack]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    setProgressValue((currentProgress / duration) * 100);
  }, [currentProgress, duration]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const currentTime = audioRef.current.currentTime;
        const seventyFivePercent = duration * 0.75;

        setCurrentProgress(currentTime);

        if (
          !hasReached75Percent &&
          currentTime >= seventyFivePercent &&
          !temporaryPause
        ) {
          setHasReached75Percent(true);
          incrementSongViews.mutate({ id: currentTrack?.id ?? "" });
        }
      }
    };

    const handleProgress = () => {
      if (audioRef.current) {
        const buffered = audioRef.current.buffered;
        if (buffered.length > 0) {
          setBufferedProgress(
            (buffered.end(buffered.length - 1) / duration) * 100,
          );
        }
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("progress", handleProgress);
      handleProgress();
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        audioRef.current.removeEventListener("progress", handleProgress);
      }
    };
  }, [
    duration,
    currentTrack,
    hasReached75Percent,
    incrementSongViews,
    temporaryPause,
  ]);

  useEffect(() => {
    setHasReached75Percent(false);
  }, [currentTrack]);

  const handleAudioEnd = () => {
    if (audioRef.current) {
      if (repeat) {
        audioRef.current.currentTime = 0;
        void audioRef.current.play();
      } else {
        if (nextTrack) {
          setCurrentTrack(nextTrack);
          localStorage.setItem("currentTrack", JSON.stringify(nextTrack));
          audioRef.current.load();
          void audioRef.current.play();
        } else {
          void audioRef.current.pause();
          setIsPlaying(false);
        }
      }
    }
  };

  const handleProgressChange = (values: number[]) => {
    if (audioRef.current && duration) {
      const newTime = (values[0] / 100) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentProgress(newTime);
    }
  };

  const handleProgressChangeStart = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setTemporaryPause(true);
    }
  };

  const handleProgressChangeEnd = () => {
    if (audioRef.current && temporaryPause) {
      void audioRef.current.play();
      setTemporaryPause(false);
    }
  };

  const handleVolumeChange = (values: number[]) => {
    const newVolume = values[0] / 100;

    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        void audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const utils = api.useUtils();

  const addToFavorites = api.user.addToFavorites.useMutation({
    onSuccess: () => {
      void utils.user.findFavoriteSongs.refetch();
    },
    onError: (err) => {
      setIsFavorite(!isFavorited);
    },
  });

  const removeFromFavorites = api.user.removeFromFavorites.useMutation({
    onSuccess: () => {
      void utils.user.findFavoriteSongs.refetch();
    },
    onError: (err) => {
      setIsFavorite(!isFavorited);
    },
  });

  // when the source element inside the audio tag has the src also make sure to set the audioRef.current.src
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = currentTrack?.songUrl;
      audioRef.current.load();
      setIsReady(true);
    }
  }, [currentTrack]);

  return {
    audioRef,
    isReady,
    setIsReady,
    duration,
    setDuration,
    currentProgress,
    setCurrentProgress,
    bufferedProgress,
    setBufferedProgress,
    isPlaying,
    setIsPlaying,
    volume,
    setVolume,
    progressValue,
    setProgressValue,
    repeat,
    setRepeat,
    lastVolume,
    setLastVolume,
    isFavorited,
    setIsFavorite,
    temporaryPause,
    setTemporaryPause,
    durationDisplay,
    elapsedDisplay,
    nextTrack,
    setNextTrack,
    prevTrack,
    setPrevTrack,
    hasReached75Percent,
    setHasReached75Percent,
    user,
    handleAudioEnd,
    handleProgressChange,
    handleProgressChangeStart,
    handleProgressChangeEnd,
    handleVolumeChange,
    togglePlayPause,
    addToFavorites,
    removeFromFavorites,
    currentTrack,
    setCurrentTrack,
  };
}
