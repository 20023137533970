/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";
import { CookieIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";

export function cookieConsentGiven(): string {
  if (!localStorage.getItem("cookie_consent")) {
    return "undecided";
  }

  return localStorage.getItem("cookie_consent")!;
}

export default function CookieConsent() {
  const posthog = usePostHog();

  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [consentGiven, setConsentGiven] = useState("");

  useEffect(() => {
    // We want this to only run once the client loads
    // or else it causes a hydration error
    setConsentGiven(cookieConsentGiven());
    setIsOpen(cookieConsentGiven() === "undecided");
  }, []);

  useEffect(() => {
    if (consentGiven !== "") {
      posthog.set_config({
        persistence: consentGiven === "yes" ? "localStorage+cookie" : "memory",
      });
    }
  }, [consentGiven]);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookie_consent", "yes");
    setConsentGiven("yes");
  };

  const handleDeclineCookies = () => {
    localStorage.setItem("cookie_consent", "no");
    setConsentGiven("no");
  };

  const accept = () => {
    setIsOpen(false);
    setTimeout(() => {
      setHide(true);
    }, 700);
    handleAcceptCookies();
  };

  const decline = () => {
    setIsOpen(false);
    setTimeout(() => {
      setHide(true);
    }, 700);
    handleDeclineCookies();
  };

  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 z-[999999] ml-auto w-full transition-transform duration-700 sm:bottom-4 sm:right-4 sm:max-w-md",
        !isOpen
          ? "translate-y-8 opacity-0 transition-[opacity,transform]"
          : "translate-y-0 opacity-100 transition-[opacity,transform]",
        hide && "hidden",
      )}
    >
      <div className="m-2 rounded-md bg-secondary">
        <div className="grid gap-2">
          <div className="flex h-14 items-center justify-between border-b border-border p-4">
            <h1 className="text-lg font-medium">We use cookies</h1>
            <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
          </div>
          <div className="p-4">
            <p className="text-sm font-normal">
              We use cookies to ensure you get the best experience on our
              website. For more information on how we use cookies, please see
              our cookie policy.
              <br />
              <br />
              <span className="text-xs">
                By clicking "
                <span className="font-medium opacity-80">Accept</span>", you
                agree to our use of cookies.
              </span>
              <br />
              <Button
                variant="link"
                className="m-0 p-0 text-xs underline hover:text-blue-500"
                asChild
              >
                <a href="#">Learn more.</a>
              </Button>
            </p>
          </div>
          <div className="flex gap-2 border-t border-border bg-background/20 p-4 py-5">
            <Button onClick={accept} className="w-full">
              Accept
            </Button>
            <Button onClick={decline} className="w-full" variant="secondary">
              Decline
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
