import React from "react";
import { MdPlayArrow, MdPause, MdVolumeUp, MdVolumeMute } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { Slider } from "./ui/slider";
import { Button } from "./ui/button";
import { Repeat } from "lucide-react";
import { beautifyName, cn } from "~/lib/utils";
import type { SerializedSong, serializedUser } from "~/types";
import Rating from "@mui/material/Rating";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "./ui/dialog";
import { Box } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";
import { HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons";
import usePlayer from "~/hooks/useMusicPlayerHook";
import { api } from "~/utils/api";
import { toast } from "./ui/use-toast";
import Link from "next/link";

export function getSongRating(song: SerializedSong) {
  const ratings = Object.values(song.ratings);

  if (ratings.length === 0) {
    return 0;
  }
  const sum = ratings.reduce((acc, curr) => acc + curr.rating, 0);

  return sum / ratings.length;
}

function AudioPlayer({
  song,
  user,
}: {
  song: SerializedSong;
  user: serializedUser;
}) {
  const {
    addToFavorites,
    removeFromFavorites,
    isFavorited,
    setIsFavorite,
    repeat,
    setRepeat,
    audioRef,
    isPlaying,
    setIsPlaying,
    isReady,
    setIsReady,
    duration,
    setDuration,
    currentProgress,
    bufferedProgress,
    setBufferedProgress,
    volume,
    setVolume,
    progressValue,
    lastVolume,
    setLastVolume,
    elapsedDisplay,
    durationDisplay,
    handleProgressChange,
    handleProgressChangeStart,
    handleProgressChangeEnd,
    handleAudioEnd,
    togglePlayPause,
    handleVolumeChange,
  } = usePlayer();
  const [value, setValue] = React.useState<number | null>(2);
  const [hover, setHover] = React.useState(-1);

  const { data: userRating } = api.song.getRating.useQuery({
    songId: song.id,
    userId: user.id,
  });

  const utils = api.useUtils();

  const ratingMutation = api.rating.create.useMutation({
    onSuccess: () => {
      toast({
        title: "Rating created",
        description: "Your rating was successfully created.",
        variant: "success",
        duration: 2000,
      });
      void utils.song.getRating.invalidate();
    },
  });

  const editRatingMutation = api.rating.update.useMutation({
    onSuccess: () => {
      toast({
        title: "Rating edited",
        description: "Your rating was successfully edited.",
        variant: "success",
        duration: 2000,
      });
      void utils.song.getRating.invalidate();
    },
  });

  const deleteRatingMutation = api.rating.delete.useMutation({
    onSuccess: () => {
      toast({
        title: "Rating deleted",
        variant: "destructive",
        description: "Your rating was successfully deleted.",
        duration: 2000,
      });
      void utils.song.getRating.invalidate();
    },
  });

  const labels: Record<string, string> = {
    0.5: "Bad",
    1: "Bad+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  return (
    <div className="relative flex w-full flex-col">
      <div className="z-50 flex w-full flex-col gap-4">
        {song && (
          <audio
            ref={audioRef}
            preload="metadata"
            onDurationChange={(e) => {
              console.log("Duration change:", e.currentTarget.duration);
              setDuration(e.currentTarget.duration);
              const buffered = e.currentTarget.buffered;
              if (buffered.length > 0) {
                setBufferedProgress(
                  (buffered.end(buffered.length - 1) /
                    e.currentTarget.duration) *
                    100,
                );
              }
            }}
            onPlaying={() => {
              console.log("Audio playing");
              setIsPlaying(true);
            }}
            onPause={() => {
              console.log("Audio paused");
              setIsPlaying(false);
            }}
            onCanPlay={() => {
              console.log("Audio can play (inline)");
              setIsReady(true);
            }}
            onCanPlayThrough={() => {
              console.log("Audio can play through (inline)");
              setIsReady(true);
            }}
            onEnded={() => {
              console.log("Audio ended");
              handleAudioEnd();
            }}
            onError={(e) => {
              console.error("Audio error:", e);
              setIsReady(false);
            }}
          >
            <source type="audio/mpeg" src={song.songUrl} />
          </audio>
        )}
        <div className="flex items-center gap-8">
          <img src={song.imageUrl} className="h-52 w-52" alt="" />
          <div className="mb-4 flex max-w-80 flex-col gap-2">
            <p className="text-xl">{song.title}</p>
            <p className="text-base">
              Album:{" "}
              <Link
                href={`/album/${song.album?.id}`}
                className="text-purple-400 underline"
              >
                {song.album?.title}
              </Link>
            </p>
            <p className="text-base">
              By:{" "}
              <Link
                href={`/profile/${song.owner.id}`}
                className="text-purple-400 underline"
              >
                {beautifyName(song.owner.name)}
              </Link>
            </p>
          </div>
          <div className="flex self-start p-4">
            {user.id !== song.owner.id && (
              <>
                {isFavorited ? (
                  <Button
                    variant="ghost"
                    className="flex items-center gap-2"
                    onClick={() => {
                      removeFromFavorites.mutate({
                        songId: song.id,
                      });

                      setIsFavorite(false);
                    }}
                  >
                    <HeartFilledIcon className="h-6 w-6 text-red-500" />
                  </Button>
                ) : (
                  <Button
                    variant="ghost"
                    className="flex items-center gap-2"
                    onClick={() => {
                      addToFavorites.mutate({
                        songId: song.id,
                      });

                      setIsFavorite(true);
                    }}
                  >
                    <HeartIcon className="h-6 w-6 text-red-500" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <div className="relative mt-4">
          <div
            className="absolute left-0 top-0 z-0 h-full rounded-lg bg-white bg-opacity-50"
            style={{ width: `${bufferedProgress}%` }}
          ></div>
          <Slider
            className="w-full cursor-pointer rounded-lg border border-primary"
            defaultValue={[progressValue]}
            value={[(currentProgress / duration) * 100]}
            max={100}
            step={1}
            onValueChange={handleProgressChange}
            onPointerDown={handleProgressChangeStart}
            onPointerUp={handleProgressChangeEnd}
          />
        </div>

        <div className="mt-4 grid grid-cols-3 items-center p-2">
          <span className="text-xs">
            {elapsedDisplay} / {durationDisplay}
          </span>
          <div className="flex items-center gap-4 justify-self-center">
            {!isReady && song ? (
              <CgSpinner size={24} className="animate-spin" />
            ) : (
              <button
                disabled={!isReady}
                onClick={togglePlayPause}
                aria-label={isPlaying ? "Pause" : "Play"}
              >
                {isPlaying ? <MdPause size={30} /> : <MdPlayArrow size={30} />}
              </button>
            )}
          </div>

          <div className="flex items-center gap-3 justify-self-end">
            <Button
              className={cn("bg-accent text-primary hover:bg-accent", "", {
                "text-green-500": repeat,
              })}
              onClick={() => setRepeat(!repeat)}
            >
              {<Repeat />}
            </Button>

            {volume === 0 ? (
              <MdVolumeMute
                className="cursor-pointer"
                size={24}
                onClick={() => {
                  setVolume(lastVolume);
                }}
              />
            ) : (
              <MdVolumeUp
                className="cursor-pointer"
                size={24}
                onClick={() => {
                  setLastVolume(volume);
                  setVolume(0);
                }}
              />
            )}
            <Slider
              className="w-20 cursor-pointer rounded-lg border border-primary"
              defaultValue={[volume * 100]}
              max={100}
              step={1}
              onValueChange={handleVolumeChange}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-4 border-t-2 border-slate-400 py-8 dark:border-slate-700">
        <div className="flex flex-col items-center gap-4 md:flex-row">
          <div>{song.views} listens</div>
          <span className="hidden sm:block">{" • "}</span>
          <div className="flex">
            <Rating
              name="read-only"
              precision={0.5}
              sx={{
                ml: 1,
                path: {
                  color: "#ff851b",
                },
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
              }
              value={getSongRating(song)}
              readOnly
            />
            <span className="ml-2">
              ({Object.values(song.ratings).length}{" "}
              {`rating${Object.values(song.ratings).length !== 1 ? "s" : ""}`})
            </span>
          </div>
          <span className="hidden sm:block">{" • "}</span>
          {user.id !== song.owner.id && (
            <div className="mt-auto flex w-32 flex-col gap-4">
              {!userRating ? (
                <>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant={"default"} size={"sm"}>
                        Leave Rating
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                        <DialogTitle>Leave Rating</DialogTitle>
                        <DialogDescription>
                          You can change your rating at any time.
                        </DialogDescription>
                      </DialogHeader>
                      <div className="grid gap-4 py-1">
                        <Box
                          sx={{
                            width: 200,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Rating
                            name="hover-feedback"
                            value={value}
                            precision={0.5}
                            sx={{
                              path: {
                                color: "#ff851b",
                              },
                            }}
                            getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          {value !== null && (
                            <Box sx={{ ml: 2 }}>
                              {labels[hover !== -1 ? hover : value]}
                            </Box>
                          )}
                        </Box>
                      </div>
                      <DialogFooter>
                        <Button
                          onClick={() => {
                            ratingMutation.mutate({
                              mediaId: song.id,
                              rating: value!,
                              mediaType: "SONG",
                            });
                          }}
                          type="submit"
                        >
                          Save changes
                        </Button>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <div className="flex gap-4">
                  <div className="flex gap-4">
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant={"default"} size={"sm"}>
                          Edit Rating
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                          <DialogTitle>Edit Rating</DialogTitle>
                          <DialogDescription>
                            You can change your rating at any time.
                          </DialogDescription>
                        </DialogHeader>
                        <div className="grid gap-4 py-1">
                          <Box
                            sx={{
                              width: 200,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Rating
                              name="hover-feedback"
                              value={value}
                              defaultValue={userRating ? userRating.rating : 0}
                              precision={0.5}
                              sx={{
                                path: {
                                  color: "#ff851b",
                                },
                              }}
                              getLabelText={getLabelText}
                              onChange={(event, newValue) => {
                                setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                            />
                            {value !== null && (
                              <Box sx={{ ml: 2 }}>
                                {labels[hover !== -1 ? hover : value]}
                              </Box>
                            )}
                          </Box>
                        </div>
                        <DialogFooter>
                          <Button
                            variant="destructive"
                            onClick={() => {
                              deleteRatingMutation.mutate({
                                ratingId: userRating.id,
                              });
                            }}
                          >
                            Delete Rating
                          </Button>
                          <Button
                            onClick={() => {
                              editRatingMutation.mutate({
                                rating: value!,
                                ratingId: userRating.id,
                              });
                            }}
                            type="submit"
                          >
                            Save changes
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AudioPlayer;
