import { NextIntlClientProvider } from "next-intl";
import { ClerkProvider } from "@clerk/nextjs";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { type ThemeProviderProps } from "next-themes/dist/types";
import type { LocalizationResource } from "@clerk/types";
import { frFR, enUS } from "@clerk/localizations";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { type SerializedSong } from "~/types";
import { shadesOfPurple } from "@clerk/themes";

type MusicPlayerContextType = {
  currentTrack: SerializedSong | null;
  setCurrentTrack: (track: SerializedSong | null) => void;
};

const MusicPlayerContext = createContext<MusicPlayerContextType | undefined>(
  undefined,
);

export function useMusicPlayer():
  | MusicPlayerContextType
  | {
      currentTrack: null;
      setCurrentTrack: (track: SerializedSong | null) => void;
    } {
  const context = useContext(MusicPlayerContext);
  if (context === undefined) {
    return {
      currentTrack: null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setCurrentTrack: () => {},
    };
  }
  return context;
}

export const MusicPlayerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentTrack, setCurrentTrack] = useState<SerializedSong | null>(null);

  useEffect(() => {
    const track = localStorage.getItem("currentTrack");
    if (track) {
      setCurrentTrack(JSON.parse(track) as SerializedSong);
    }
  }, []);

  const updateCurrentTrack = (track: SerializedSong | null) => {
    setCurrentTrack(track);
    if (track) {
      localStorage.setItem("currentTrack", JSON.stringify(track));
      
      // Update recently played songs
      const storedRecentSongs = localStorage.getItem("recentlyPlayed");
      const recentSongs = storedRecentSongs ? JSON.parse(storedRecentSongs) as SerializedSong[] : [];
      const updatedRecentSongs = [track, ...recentSongs.filter(s => s.id !== track.id)].slice(0, 20);
      localStorage.setItem("recentlyPlayed", JSON.stringify(updatedRecentSongs));
    }
  };

  const value = {
    currentTrack,
    setCurrentTrack: updateCurrentTrack,
  };

  return (
    <MusicPlayerContext.Provider value={value}>
      {children}
    </MusicPlayerContext.Provider>
  );
};

function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}

export default function Providers({
  children,
  pageProps,
}: {
  children: React.ReactNode;
  pageProps: {
    now: number;
    messages: IntlMessages;
  };
}) {
  const router = useRouter();
  const { locale: activeLocale } = router;
  const locale =
    activeLocale === "ar" ? arAr : activeLocale === "fr" ? frFR : enUS;
  return (
    <ClerkProvider
      localization={locale}
      {...pageProps}
      appearance={{
        baseTheme: shadesOfPurple,
      }}
    >
      <NextIntlClientProvider
        locale="en-US"
        timeZone="UTC"
        formats={{
          dateTime: {
            short: {
              day: "numeric",
              month: "short",
              year: "numeric",
            },
          },
        }}
        now={new Date(pageProps.now)}
        messages={pageProps.messages}
      >
        <MusicPlayerProvider>
          <ThemeProvider attribute="class" defaultTheme="dark" enableSystem>
            {children}
          </ThemeProvider>
        </MusicPlayerProvider>
      </NextIntlClientProvider>
    </ClerkProvider>
  );
}

const arAr: LocalizationResource = {
  locale: "ar-Ar",
  socialButtonsBlockButton: "تسجيل الدخول باستخدام {{provider|titleize}}",
  dividerText: "أو",
  formFieldLabel__emailAddress: "عنوان البريد الإلكتروني",
  formFieldLabel__password: "كلمه السر",
  formButtonPrimary: "تسجيل الدخول",
  signIn: {
    start: {
      title: "تسجيل الدخول",
      subtitle: "للمتابعة إلى {{applicationName}}",
      actionText: "لا يوجد لديك حساب؟",
      actionLink: "سجل",
      actionLink__use_email: "استخدم البريد الإلكتروني",
      actionLink__use_phone: "استخدم الهاتف",
      actionLink__use_username: "استخدم اسم المستخدم",
      actionLink__use_email_username:
        "استخدم البريد الإلكتروني أو اسم المستخدم",
    },

    password: {
      title: "أدخل كلمة المرور الخاصة بك",
      subtitle: "للمتابعة إلى {{applicationName}}",
      actionLink: "استخدم طريقة أخرى",
    },
    forgotPasswordAlternativeMethods: {
      title: "هل نسيت كلمة المرور؟",
      label__alternativeMethods: "أو، سجل الدخول باستخدام طريقة أخرى.",
      blockButton__resetPassword: "إعادة تعيين كلمة المرور الخاصة بك",
    },
    forgotPassword: {
      formTitle: "إعادة تعيين كلمة المرور",
      resendButton: "لم تتلق رمزًا؟ إعادة إرسال",
      subtitle: "للمتابعة إلى {{applicationName}}",
      subtitle_email: "للمتابعة إلى {{applicationName}}",
      subtitle_phone: "للمتابعة إلى {{applicationName}}",
      title: "إعادة تعيين كلمة المرور",
    },
    resetPassword: {
      title: "إعادة تعيين كلمة المرور",
      formButtonPrimary: "إعادة تعيين كلمة المرور",
      successMessage:
        "تم تغيير كلمة المرور بنجاح. تسجيل الدخول ، يرجى الانتظار لحظة.",
    },
    resetPasswordMfa: {
      detailsLabel:
        "نحن بحاجة إلى التحقق من هويتك قبل إعادة تعيين كلمة المرور الخاصة بك.",
    },
    emailCode: {
      title: "تحقق من بريدك الإلكتروني",
      subtitle: "للمتابعة إلى {{applicationName}}",
      formTitle: "رمز التحقق",
      resendButton: "لم تتلق رمزًا؟ إعادة إرسال",
    },
    emailLink: {
      title: "تحقق من بريدك الإلكتروني",
      subtitle: "للمتابعة إلى {{applicationName}}",
      formTitle: "رابط التحقق",
      formSubtitle: "استخدم رابط التحقق المرسل إلى بريدك الإلكتروني",
      resendButton: "لم تتلق رابطًا؟ إعادة إرسال",
      unusedTab: {
        title: "يمكنك إغلاق هذه العلامة التبويب",
      },
      verified: {
        title: "تم تسجيل الدخول بنجاح",
        subtitle: "سيتم توجيهك قريبًا",
      },
      verifiedSwitchTab: {
        subtitle: "العودة إلى علامة التبويب الأصلية للمتابعة",
        titleNewTab: "تم تسجيل الدخول في علامة تبويب أخرى",
        subtitleNewTab: "العودة إلى علامة التبويب الجديدة المفتوحة للمتابعة",
      },
      loading: {
        title: "تسجيل الدخول ...",
        subtitle: "سيتم توجيهك قريبًا",
      },
      failed: {
        title: "رابط التحقق هذا غير صالح",
        subtitle: "العودة إلى علامة التبويب الأصلية للمتابعة.",
      },
    },
  },
  signUp: {
    start: {
      title: "سجل حسابا",
      subtitle: "للمتابعة إلى {{applicationName}}",
      actionText: "هل لديك حساب؟",
      actionLink: "تسجيل الدخول",
    },
    emailCode: {
      title: "تحقق من بريدك الإلكتروني",
      subtitle: "للمتابعة إلى {{applicationName}}",
      formTitle: "رمز التحقق",
      formSubtitle: "أدخل رمز التحقق المرسل إلى عنوان بريدك الإلكتروني",
      resendButton: "لم تتلق رمزًا؟ إعادة إرسال",
    },
    emailLink: {
      title: "تحقق من بريدك الإلكتروني",
      subtitle: "للمتابعة إلى {{applicationName}}",
      formTitle: "رابط التحقق",
      formSubtitle: "استخدم رابط التحقق المرسل إلى بريدك الإلكتروني",
      resendButton: "لم تتلق رابطًا؟ إعادة إرسال",

      verified: {
        title: "تم تسجيل الدخول بنجاح",
      },
      verifiedSwitchTab: {
        subtitle: "العودة إلى علامة التبويب الأصلية للمتابعة",
      },
      loading: {
        title: "تسجيل الدخول ...",
      },
    },
  },
};
