/* eslint-disable @next/next/no-html-link-for-pages */
import Link from "next/link";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  SignUpButton,
  useClerk,
} from "@clerk/nextjs";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "~/components/ui/navigation-menu";
import { Input } from "~/components/ui/input";
import { Button } from "./ui/button";

import type { GetStaticPropsContext } from "next";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

import React, { useState, useCallback } from "react";
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { debounce } from "lodash";
import {
  LifeBuoy,
  LogOut,
  Settings,
  User,
  Info,
  Search,
  Bell,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "./ui/dropdown-menu";
import { type User as PrismaUser } from "@prisma/client";

export default function Navbar({ user }: { user: PrismaUser }) {
  const router = useRouter();
  const { signOut } = useClerk();
  const initialSearch = Array.isArray(router.query.search)
    ? router.query.search[0] ?? ""
    : router.query.search ?? "";
  const [search, setSearch] = useState<string>(initialSearch);

  const debouncedSearch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    debounce((query: string) => {
      void router.push({
        pathname: "/explore",
        query: { search: query },
      });
    }, 2500),
    [],
  ) as ((query: string) => void) & { flush: () => void };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue);
    debouncedSearch(newValue);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      debouncedSearch.flush();
    }
  };

  React.useEffect(() => {
    if (Array.isArray(router.query.search)) {
      setSearch(router.query.search[0] ?? "");
    } else {
      setSearch(router.query.search ?? "");
    }
  }, [router.query.search]);

  const direction = router.locale === "ar" ? "rtl" : "ltr";
  const t = useTranslations("Navigation");

  return (
    <NavigationMenu
      dir={direction}
      className="flex max-w-none justify-between bg-background px-8 py-4 shadow-lg"
    >
      <NavigationMenuList className="flex">
        <NavigationMenuItem>
          <Link href="/">
            <img className="h-12" src="/logo.png" alt="TrackTalk logo" />
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>

      <SignedIn>
        <NavigationMenuList className="flex">
          <NavigationMenuItem>
            <div className="relative w-96 rounded-full bg-black">
              <Search
                className="absolute left-0 ml-4"
                style={{ top: "50%", transform: "translateY(-50%)" }}
                size={16}
              />
              <Input
                value={search}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                className="rounded-full bg-black py-2 pl-12 pr-4" // Adjust padding to accommodate the icon inside
                type="text"
                placeholder="Search"
              />
            </div>
          </NavigationMenuItem>
        </NavigationMenuList>
      </SignedIn>
      <NavigationMenuList className="flex">
        <NavigationMenuItem>
          <SignedIn>
            <div className="flex items-center gap-4">
              <DropdownMenu>
                <DropdownMenuTrigger asChild disabled>
                  <button className="cursor-pointer rounded-full bg-black p-2 disabled:cursor-not-allowed disabled:opacity-50">
                    <Bell className="h-5 w-5 cursor-not-allowed" />
                  </button>
                </DropdownMenuTrigger>
              </DropdownMenu>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Avatar className="h-9 w-9 cursor-pointer">
                    <AvatarImage src={user?.profilePic ?? ""}></AvatarImage>
                    <AvatarFallback>
                      {user?.name.slice(0, 2).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="-translate-x-1/3 px-4">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <Link href={`/profile/${user?.name}`}>
                      <DropdownMenuItem>
                        <User className="mr-2 h-4 w-4" />
                        <span>Profile</span>
                      </DropdownMenuItem>
                    </Link>

                    <DropdownMenuItem asChild>
                      <Link href="/user-profile/settings">
                        <Settings className="mr-2 h-4 w-4" />
                        <span>Settings</span>
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>

                  <DropdownMenuSeparator />
                  <Link href="/contact">
                    <DropdownMenuItem>
                      <LifeBuoy className="mr-2 h-4 w-4" />
                      <span>Support</span>
                    </DropdownMenuItem>
                  </Link>
                  <Link href="/about">
                    <DropdownMenuItem>
                      <Info className="mr-2 h-4 w-4" />
                      <span>About us</span>
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() =>
                      void signOut(() => window.location.replace("/about"))
                    }
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </SignedIn>
          <SignedOut>
            <div className="flex items-center gap-3">
              <Button className="w-20 border-2 border-[#3B2A70] bg-background text-foreground hover:bg-[#3B2A70]">
                <SignUpButton mode="modal">{t("signup")}</SignUpButton>
              </Button>
              <Button className="w-20 bg-[#5F5CE3] text-white shadow-lg hover:bg-[#4F4CE3]">
                <SignInButton mode="modal">{t("signin")}</SignInButton>
              </Button>
            </div>
          </SignedOut>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = (
    (await import(`@/../messages/${locale}.json`)) as {
      default: IntlMessages;
    }
  ).default;

  return {
    props: {
      messages,
    },
  };
}
